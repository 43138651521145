import React, { useRef, useState, useEffect } from "react";
import {
  GoogleMap,
  DirectionsRenderer,
  InfoWindow,
  MarkerF,
  useLoadScript,
} from "@react-google-maps/api";
import LocationIcon from "../../assets/images/flag.png";
import carIcon from "../../assets/images/car.png";
// Import your car icon

const googleStyle = [
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#d3d3d3",
      },
    ],
  },
  {
    featureType: "transit",
    stylers: [
      {
        color: "#808080",
      },
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#b3b3b3",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#ffffff",
      },
      {
        weight: 1.8,
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#d7d7d7",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#ebebeb",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        color: "#a7a7a7",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#efefef",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#696969",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "labels.text.fill",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#737373",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#d6d6d6",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {},
  {
    featureType: "poi",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#dadada",
      },
    ],
  },
];

const FollowMap = ({
  latitude,
  longitude,
  directionsLatitude,
  directionsLongitude,
}) => {
  const mapRef = useRef(null);
  const [directions, setDirections] = useState(null);
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);
  const libraries = ["places"];

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDEMcchGaq5u9_6pRylftzvliVrm2KW0Qw",
    libraries,
  });

  // Mock data for markers
  const originPosition = { lat: latitude, lng: longitude };
  const destinationPosition = {
    lat: directionsLatitude,
    lng: directionsLongitude,
  };
  const mapContainerStyle = {
    height: "100vh",
  };
  useEffect(() => {
    if (isLoaded && !directions) {
      // Calculate route between the two markers
      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin: originPosition,
          destination: destinationPosition,
          travelMode: "DRIVING",
        },
        (result, status) => {
          if (status === "OK") {
            setDirections(result);
          } else {
            console.error("Directions request failed:", status);
          }
        }
      );
    }
  }, [isLoaded, directions]);

  const handleOnLoad = (map) => {
    mapRef.current = map;
  };

  return (
    <div className="google_map container">
      {isLoaded && (
        <GoogleMap
          onLoad={handleOnLoad}
          mapContainerStyle={mapContainerStyle}
          options={{
            styles: googleStyle,
          }}
          mapTypeId="terrain"
          zoom={13}
          center={originPosition}
        >
          {directions && (
            <DirectionsRenderer
              directions={directions}
              options={{
                polylineOptions: {
                  strokeColor: "#000000", // Black color for the route line
                  strokeOpacity: 1,
                  strokeWeight: 2,
                },
              }}
            />
          )}

          {/* Use custom icons as markers */}
          <MarkerF
            position={originPosition}
            icon={{
              url: carIcon,
              scaledSize: new window.google.maps.Size(80, 80),
              anchor: new window.google.maps.Point(20, 20), // Adjust anchor point if needed
            }}
            onClick={() => setInfoWindowOpen(true)}
          >
            {/* {infoWindowOpen && (
              <InfoWindow onCloseClick={() => setInfoWindowOpen(false)}>
                <div>
                  <h3>Origin Info</h3>
                  <p>Additional information about the origin</p>
                </div>
              </InfoWindow>
            )} */}
          </MarkerF>

          <MarkerF
            position={destinationPosition}
            icon={{
              url: LocationIcon,
              scaledSize: new window.google.maps.Size(50, 50),
              anchor: new window.google.maps.Point(20, 20), // Adjust anchor point if needed
            }}
            onClick={() => setInfoWindowOpen(true)}
          >
            {infoWindowOpen && (
              <InfoWindow onCloseClick={() => setInfoWindowOpen(false)}>
                <div>
                  <h3>Información de destino</h3>
                  <p>Información adicional sobre el destino</p>
                </div>
              </InfoWindow>
            )}
          </MarkerF>
        </GoogleMap>
      )}

      {/* <img src={carIcon} alt="" className="h-40 w-40" /> */}
    </div>
  );
};

export default FollowMap;
