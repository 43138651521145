import BackBtn from "../Components/BackBtn/BackBtn";
import FormBtn from "../Components/FormBtn/FormBtn";
import SecurityMsg from "../Components/SecurityMsg/SecurityMsg";
import { followIcon, menuButton } from "../assets/images";
import React, { useEffect, useState } from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import { useAuthStore } from "../store/auth";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { FollowMestopAPI } from "../api/follow_me";
import FollowMap from "../Components/FollowMap/FollowMap";
import ButtonLoader from "../Components/Loader/ButtonLoader";

const libraries = ["places"];

const FollowRide = () => {
  const { cordinates, user, bookingData } = useAuthStore();
  const navigate = useNavigate();

  //on window close stop follow me function
  function useBeforeUnload(callback) {
    useEffect(() => {
      window.addEventListener("beforeunload", callback);
      return () => {
        window.removeEventListener("beforeunload", callback);
      };
    }, [callback]);
  }

  useBeforeUnload(() => {
    FollowMestop({
      final_destination: user?.destination,
      uid: user?.uid,
      latitude:
        // 23.071298202877863,
        cordinates?.location?._latitude.toString(),
      longitude:
        // 72.51780074021997,
        cordinates?.location?._longitude.toString(),
    });
  });

  const { mutate: FollowMestop, isLoading } = useMutation(FollowMestopAPI, {
    onSuccess: (data) => {
      if (data?.success) {
        console.log(data.data, "Response");
        navigate("/home");
      }
    },
    onError: (data) => {
      console.log(data?.message, "something went wrong");
    },
  });
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyBiLNiGvUl_DnTA6M9uG-4gAbGLwb7rtQc",
    libraries,
  });

  if (loadError) {
    return <div>Error al cargar mapas</div>;
  }

  if (!isLoaded) {
    return <div>Cargando mapas</div>;
  }

  const handleStop = () => {
    const data = {
      final_destination: user?.destination,
      uid: user?.uid,
      latitude:
        // 23.071298202877863,
        cordinates?.location?._latitude.toString(),
      longitude:
        // 72.51780074021997,
        cordinates?.location?._longitude.toString(),
    };

    FollowMestop(data);
  };

  return (
    <div className="container relative white_bg_effect">
      <BackBtn
        backName="Sigue mi viaje"
        backLink="/home"
        backImg={menuButton}
      />
      {/* <GoogleMapSec /> */}
      <div className="google_map container">
        <FollowMap
          latitude={bookingData?.pickup?.address?.coordinate?.latitude}
          longitude={bookingData?.pickup?.address?.coordinate?.longitude}
          directionsLatitude={
            bookingData?.destination?.address?.coordinate?.latitude
          }
          directionsLongitude={
            bookingData?.destination?.address?.coordinate?.longitude
          }
        />
      </div>
      <div className="absolute left-0 right-0 bottom-0 lg:mx-10 z-10 pb-10 mx-6">
        <SecurityMsg alertMsg="Su viaje ahora es seguido por la compañía de seguridad" />

        {isLoading ? (
          <button
            type="submit"
            className="w-full lg:w-80 flex items-center p-2 rounded-[29px] bg-[#7258F9] gap-2 mx-auto"
          >
            <ButtonLoader />
          </button>
        ) : (
          <button
            type="submit"
            className="w-full lg:w-80 flex items-center p-2 rounded-[29px] bg-[#7258F9] gap-2 mx-auto"
            onClick={handleStop}
          >
            <div className=" w-12 h-12 rounded-full bg-darkBlue flex items-center justify-center flex-shrink-0">
              <img src={followIcon} alt="follow me" className="" />
            </div>
            <div className="text-white w-full text-center">Dejar de seguir</div>
          </button>
        )}
      </div>
    </div>
  );
};

export default FollowRide;
