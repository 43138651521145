import BackBtn from "../Components/BackBtn/BackBtn";
import GoogleMapSec from "../Components/GoogleMap/GoogleMap";
import { appDownload, menuButton } from "../assets/images";
import { sosIcon } from "../assets/images";
import { useState } from "react";
import { squadIcon, followIcon } from "../assets/images";
import { useAuthStore } from "../store/auth";
import { Link, useNavigate } from "react-router-dom";
import ButtonLoader from "../Components/Loader/ButtonLoader";
import axios from "axios";
import Notiflix from "notiflix";

const HomeSection = () => {
  const { cordinates, setBookingData, securityId } = useAuthStore();

  let phoneNumberWithoutCountryCode = parseInt(
    cordinates?.phoneNumber?.toString().slice(3)
  );

  const navigate = useNavigate();
  const currentDate = new Date();
  const year = currentDate.getUTCFullYear();
  const month = String(currentDate.getUTCMonth() + 1).padStart(2, "0"); // Adding 1 as months are zero-based
  const day = String(currentDate.getUTCDate()).padStart(2, "0");

  const toDate = `${year}-${month}-${day}T12:00:00.00Z`;

  const fromDate = `${year}-${month}-${day}T00:00:00.00Z`;

  const BookingData = {
    from: fromDate,
    to: toDate,
    telephoneNumber: phoneNumberWithoutCountryCode,
    // telephoneNumber: parseInt(cordinates?.phoneNumber),
    companyIds: [],
    capabilities: [],
    capabilityMatchType: "Any",
    exactMatch: false,
    ignorePostcode: true,
    ignoreTown: true,
    types: ["Active", "Mobile"],
  };

  const [isLoading1, setIsLoading] = useState(false);

  const HandleSearchBooking = async () => {
    try {
      setIsLoading(true);
      await axios
        .post(
          "https://autocab-api.azure-api.net/booking/v1/1.2/search",
          BookingData,
          {
            headers: {
              "Ocp-Apim-Subscription-Key": securityId?.Subscription_Key,
            },
          }
        )
        .then((response) => {
          // console.log(response, "RESSSSS");
          if (response && response?.bookings[0]) {
            // setBooking(response?.bookings[0]);
            Notiflix.Notify.success("Datos de reserva obtenidos correctamente");
            setBookingData(response?.bookings[0]);
            handleFollowMe({
              bookingDest: response?.bookings[0]?.destination?.address?.text,
              bookingLat:
                response?.bookings[0]?.destination?.address?.coordinate
                  ?.latitude,
              bookingLon:
                response?.bookings[0]?.destination?.address?.coordinate
                  ?.longitude,
            });
          } else {
            Notiflix.Notify.failure(
              "¡Lo siento! No encontramos los datos de tu reserva, inténtalo de nuevo más tarde."
            );
            setIsLoading(false);
          }
        });
    } catch (error) {
      console.log(error, "Error");
      setIsLoading(false);
      Notiflix.Notify.failure("¡Algo salió mal!");
    }
  };

  const handleFollowMe = async ({ bookingDest, bookingLat, bookingLon }) => {
    let data = {
      uid: cordinates?.uid,
      list_ids: [cordinates?.uid],
      destination: bookingDest,
      time_run_cron_job: 1,
      locationDestination: {
        latitude: bookingLat,
        longitude: bookingLon,
      },
      security_company_id: securityId?.id,
    };

    try {
      const response = await axios.post(
        "https://us-central1-ursafe-cba93.cloudfunctions.net/api_v2/api/follow-me/send-invitation",
        data
      );
      setIsLoading(false);
      console.log(response, "handleFollowMe");
      navigate("/follow-my-ride");
    } catch (error) {
      setIsLoading(false);

      console.log(error, "Followme Error");
    }
  };

  const HandleLocation = () => {
    if (cordinates) {
      // console.log("cordina");

      navigate("/emergency-sos");
    } else {
      // console.log("home");
      navigate("/home");
    }
  };

  const getDeviceType = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // iOS detection
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "ios";
    }

    // Android detection
    if (/android/i.test(userAgent)) {
      return "android";
    }

    // Default to unknown
    return "unknown";
  };

  // Get the device type
  const deviceType = getDeviceType();

  // Define the appropriate link based on device type
  let NavigateLink = "";
  if (deviceType === "ios") {
    NavigateLink = "https://apps.apple.com/in/app/the-ursafe-app/id1481482887";
  } else if (deviceType === "android") {
    NavigateLink =
      "https://play.google.com/store/apps/details?id=com.ursafe&pli=1";
  }

  return (
    <div className="container relative white_bg_effect">
      <BackBtn
        backName="SOS de emergencia"
        backLink="/home"
        backImg={menuButton}
      />
      <GoogleMapSec />
      <div className=" absolute left-0 right-0 bottom-0 z-10">
        {/* <HomeRedirect /> */}
        <div className="grid grid-cols-2 gap-2 h-64">
          <div className="bg-gredientBlue rounded-r-[43px] text-end">
            {isLoading1 ? (
              <button
                type="button"
                className="inline-flex items-center gap-7 px-4 py-4 h-max"
              >
                <ButtonLoader />
              </button>
            ) : (
              <button
                type="button"
                // onClick={handleFollow}
                onClick={HandleSearchBooking}
                className="inline-flex items-center gap-7 px-4 py-4 h-max"
              >
                <div className="font-bold text-sm">Sigue mi viaje</div>
                <div className=" w-12 h-12 rounded-full bg-darkBlue flex items-center justify-center">
                  <img src={followIcon} alt="follow me" className="" />
                </div>
              </button>
            )}
          </div>
          <div className="bg-gredientBlue rounded-l-[43px] text-start">
            <a
              href="/my-squad"
              className="inline-flex items-center gap-7 px-4 py-4 h-max"
            >
              <div className=" w-12 h-12 rounded-full bg-darkBlue flex items-center justify-center">
                <img src={squadIcon} alt="follow me" className="" />
              </div>
              <div className="font-bold text-sm">mi equipo</div>
            </a>
          </div>
        </div>

        <div className=" -mt-40 z-10 relative mx-0 lg:mx-10">
          <div className="mx-10 lg:mx-10">
            <button
              // to={props.hrefLink}
              onClick={HandleLocation}
              className="flex flex-col w-full items-center bg-red p-5 pb-8 rounded-t-[43px] border-4 border-white border-b-0"
            >
              <div className="flex items-center justify-center gap-3">
                <img src={sosIcon} alt="sos" className="" />
                <div className="text-white fw-bold">Activar SOS</div>
              </div>
              <div className="text-white fw-bold">
                transmisión de video en vivo a la empresa de seguridad y a sus
                seres queridos
              </div>
            </button>
          </div>
          <Link to={NavigateLink} className=" -mt-3">
            <img
              src={appDownload}
              alt="app/play store"
              className="w-full h-32 lg:h-56 object-cover rounded-3xl"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomeSection;
